import React from "react";
import Hint from "../hint/Hint";
import "./pairTierIcon.css";

function PairTierIcon({ position, tier }) {
  const getTierParams = (tier) => {
    const performanceTierParams = {
      top: {
        1: { emoji: "🥇", className: "orion__tier-icon-bigger" },
        2: { emoji: "🥈", className: "orion__tier-icon-bigger" },
        3: { emoji: "🥉", className: "orion__tier-icon-bigger" },
        4: { emoji: "🏅", className: "orion__tier-icon-bigger" },
        5: { emoji: "🏅", className: "orion__tier-icon-bigger" },
      },
      average: { emoji: "⚠️", className: "orion__tier-icon" },
      bad: { emoji: "❌", className: "orion__tier-icon" },
      good: { emoji: "✅", className: "orion__tier-icon" },
      worst: { emoji: "☠️", className: "orion__tier-icon" },
      default: { emoji: "❓", className: "orion__tier-icon" },
    };
    if (position >= 1 && position <= 5) {
      return performanceTierParams.top[position];
    }
    return performanceTierParams[tier];
  };

  const getTierHint = (tier, position) => {
    const topPositionHints = {
      1: "Melhor criptoativo nos últimos tempos",
      2: "Segundo melhor criptoativo nos últimos tempos",
      3: "Terceiro melhor criptoativo nos últimos tempos",
      4: "Entre os melhores criptoativos nos últimos tempos",
      5: "Entre os melhores criptoativos nos últimos tempos",
    };

    const tierHints = {
      average: "Desempenho médio nas últimas trades",
      bad: "Desempenho ruim nas últimas trades",
      good: "Bom desempenho nas últimas trades",
      worst: "Desempenho péssimo nas últimas trades",
    };

    if (position >= 1 && position <= 5) {
      return topPositionHints[position];
    }

    return tierHints[tier] || "Desempenho desconhecido";
  };

  const { className, emoji } = getTierParams(tier);
  const hintText = getTierHint(tier, position);

  return (
    <Hint text={hintText}>
      <span className={className}>{emoji}</span>
    </Hint>
  );
}

export default PairTierIcon;
