import React, { useState, useEffect, useRef } from "react";
import "./index.css";

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CheckIcon,
  MapPinIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo-light.png";
import dashboardScreenshots from "../../assets/theros-screenshot.png";
import phoneImage from "../../assets/grafico-celular.png";
import orionBadge from "../../assets/orion-badge.png";
import { default as FaqSection } from "./sections/faq";

import { trades } from "./constants/trades";
import useIcons from "../../hooks/useIcons";

import { Footer } from "../../components";
import { Navbar } from "./components";

import babson from "../../assets/babson.jpg";
import orionStore from "../../assets/orion-store.png";

function LandingPage() {
  const { getIconByCoinSymbol } = useIcons();
  const carousel = useRef(null);
  const [, setWidth] = useState(window.innerWidth);

  const plans = [
    {
      id: 1,
      title: "Plano Anual",
      price: "R$914",
      previousPrice: "R$1.524",
      description: "ECONOMIZE 40%",
      isBest: true,
    },
    {
      id: 2,
      title: "Plano Trimestral",
      price: "R$285",
      previousPrice: "R$381",
      isBest: false,
    },
    {
      id: 3,
      title: "Plano Mensal",
      price: "R$127",
      previousPrice: "PAGAMENTO ÚNICO",
      isBest: false,
    },
  ];

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, [setWidth]);

  const navigate = useNavigate();

  const navigateToSignUp = () => {
    navigate("/cadastro");
  };

  const handleCarouselLeftClick = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft -= carousel.current.offsetWidth;
  };

  const handleCarouselRightClick = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft += carousel.current.offsetWidth;
  };

  const isDragging = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft -= e.movementX;
  };

  return (
    <div className="orion__landing-page">
      <Navbar page={"landing"} />
      <div className="orion__landing-content">
        <div className="orion__landing-hero">
          <div className="title-container">
            <h1>Conheça as criptos mais lucrativas</h1>
            <h2>
              através dos nossos <b className="gradient">sinais estratégicos</b>
            </h2>
          </div>
          <p>
            Receba sinais de <b>compra</b> e <b>venda</b> de criptoativos em
            tempo real
          </p>
          <div className="orion__landing-hero-buttons">
            <a
              className="orion__default-button"
              href="#orion__landing-presentation"
            >
              Conheça nossa plataforma
            </a>
          </div>
          <small>
            Plano Mensal: R$ 127,00.{" "}
            <a className="underlined" href="#orion__plan-section">
              Ver outras opções
            </a>
          </small>
          <div className="hero">
            <img
              alt="Capturas de tela do dashboard"
              src={dashboardScreenshots}
            />
          </div>
        </div>
        <div
          className="orion__landing-presentation"
          id="orion__landing-presentation"
        >
          <div className="orion__presentation-left-column">
            <h2 className="orion__landing-title">SOBRE A PLATAFORMA</h2>
            <h3>
              Auxiliamos você a tomar decisões com base em{" "}
              <b className="gradient">sinais estratégicos</b>
            </h3>
            <p>
              Nossas estratégias são desenvolvidas por uma equipe de
              especialistas com anos de experiência no mercado de criptoativos,
              utilizando uma combinação de técnicas de análise técnica e
              fundamental para identificar as melhores oportunidades de
              investimento.
            </p>
            <p></p>
            <div className="orion__presentation-button">
              <a style={{ textDecoration: "none" }} href="#orion__plan-section">
                <button className="orion__default-button">
                  Conheça nossos planos
                </button>
              </a>
              <a href="#orion__faq-section">Dúvidas? Confira nosso FAQ</a>
            </div>
          </div>
          <div className="orion__presentation-right-column">
            <img
              className="orion__phone-image"
              src={phoneImage}
              alt="grafico-celular"
            ></img>
          </div>
        </div>

        <div className="orion__gradient-section">
          <div className="orion__features-section" id="orion__features-section">
            <div className="orion__features-header">
              <div className="orion__features-header-text">
                <small>BENEFÍCIOS</small>
                <p>
                  Nossa plataforma é ideal para quem não tem tempo para
                  acompanhar constantemente o mercado cripto e não quer perder a
                  oportunidade de investir!
                </p>
              </div>
              <div className="orion__carousel-buttons-wrapper">
                <div
                  className="orion__carousel-button"
                  onClick={handleCarouselLeftClick}
                >
                  <ChevronLeftIcon />
                </div>
                <div
                  className="orion__carousel-button"
                  onClick={handleCarouselRightClick}
                >
                  <ChevronRightIcon />
                </div>
              </div>
            </div>
            <div
              className="orion__carousel-wrapper"
              ref={carousel}
              onDrag={isDragging}
            >
              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>RECEBA VIA TELEGRAM</small>
                  <p>
                    Receba sinais diretamente em seu Telegram em um canal
                    exclusivo
                  </p>
                </div>
              </div>
              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>MAIORES LUCROS</small>
                  <p>
                    Aumente suas chances de sucesso no mercado de criptoativos
                  </p>
                </div>
              </div>
              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>ECONOMIA DE TEMPO</small>
                  <p>Ganhe tempo e evite erros de análise</p>
                </div>
              </div>

              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>MAIS DE 240 CRIPTOS</small>
                  <p>
                    Aprenda sobre o mercado de criptoativos com os melhores
                    especialistas
                  </p>
                </div>
              </div>
              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>REALIZE OPERAÇOES</small>
                  <p>Faça operações na corretora de sua preferência</p>
                </div>
              </div>
              <div className="orion__carousel-card-container">
                <div className="orion__carousel-card">
                  <small>RECEBA VIA WHATSAPP</small>
                  <p>Em breve!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="orion__signal-section">
            <div className="orion__signal-list-header">
              <img
                alt="Logo alternativo da Orion Sinais"
                src={orionBadge}
                className="orion__badge"
              ></img>
              <div className="orion__signal-list-header-text">
                <h2>Acompanhe os sinais dos principais criptoativos</h2>
                <p>
                  Nossa plataforma fornece todo o histórico de sinais de compra
                  e venda para cada ativo em tempo real. Aproveite as melhores
                  oportunidades do mercado sem perder tempo com análises
                  complexas e inconclusivas!{" "}
                </p>
              </div>
              <div className="orion__signal-tags">
                {trades.map((trade) => (
                  <div className="orion__signal-tag">
                    <div className="orion__signal-tag-name">
                      <img
                        src={getIconByCoinSymbol(trade.simbolo)}
                        alt={trade.simbolo}
                        className="orion__signal-icon"
                      />
                      <small>{trade.nome}</small>
                    </div>

                    <small className="orion__signal-tag-profit">
                      {trade.percentual}
                    </small>
                  </div>
                ))}
              </div>
            </div>
            <div className="orion__signal-list-right-column">
              <div className="orion__signal-list">
                {trades.map((trade) => (
                  <div className="orion__signal" key={trade.simbolo}>
                    <div className="orion__signal-info">
                      <img
                        src={getIconByCoinSymbol(trade.simbolo)}
                        alt={trade.simbolo}
                        className="orion__signal-icon"
                      />
                      <div className="orion__signal-name">
                        <p>{trade.nome}</p>
                        <small>{trade.simbolo.toUpperCase() + "/USDT"}</small>
                      </div>
                    </div>
                    <div className="orion__signal-data-wrapper">
                      <div className="orion__signal-data">
                        <small>DATA DA COMPRA</small>
                        <p>{trade.dataCompra}</p>
                      </div>
                      <div className="orion__signal-data">
                        <small>VALOR DA COMPRA</small>
                        <p>{trade.valorCompra}</p>
                      </div>
                      <div className="orion__signal-data">
                        <small>DATA DA VENDA</small>
                        <p>{trade.dataVenda}</p>
                      </div>
                      <div className="orion__signal-data">
                        <small>VALOR DA VENDA</small>
                        <p>{trade.valorVenda}</p>
                      </div>
                      <div className="orion__signal-data">
                        <small>DURAÇÃO</small>
                        <p>{trade.duracao}</p>
                      </div>
                      <div className="orion__signal-data">
                        <small>LUCRO</small>
                        <p
                          className={
                            trade.percentual.includes("+")
                              ? "positive"
                              : "negative"
                          }
                        >
                          {trade.percentual}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="orion__plan-section" id="orion__plan-section">
            <div className="orion__plan-section-title">
              <h2 className="orion__landing-title">PLANO</h2>
              <h3>Pronto para receber sinais?</h3>
              <p>
                Oferecemos os melhores possibilidades de sinais de venda e
                compra, confira nossos planos!
              </p>
              <p>
                Caso não fique satisfeito, devolvemos seu dinheiro{" "}
                <b>em até 7 dias.</b>
              </p>
              <small>TODOS OS PLANOS OFERECEM:</small>
              <div className="orion__plan-section-benefit">
                <CheckIcon className="benefit-icon" />
                <p>Simulador de ganhos</p>
              </div>
              <div className="orion__plan-section-benefit">
                <CheckIcon className="benefit-icon" />
                <p>Sinais via Telegram</p>
              </div>
              <div className="orion__plan-section-benefit">
                <CheckIcon className="benefit-icon" />
                <p>Compra 100% segura</p>
              </div>
              <div className="orion__plan-section-benefit">
                <CheckIcon className="benefit-icon" />
                <p>Pagamento via Pix</p>
              </div>
            </div>
            <div className="orion__plan-cards-wrapper">
              {plans.map((plan) => (
                <div
                  key={plan.id}
                  className={`orion__plan-card ${plan.isBest ? "best" : ""}`}
                >
                  {plan.isBest && (
                    <div className="orion__plan-card-banner">
                      {plan.description}
                    </div>
                  )}
                  <div className="orion__plan-card-header">
                    <div className="orion__plan-card-previous-price">
                      <small>DE </small>
                      <small className="dashed">{plan.previousPrice}</small>
                      <small> POR</small>
                    </div>
                    <h1>{plan.price}</h1>
                    <p>{plan.title}</p>
                  </div>
                  <div className="orion__plan-card-button-wrapper">
                    <button
                      className="orion__default-button"
                      onClick={navigateToSignUp}
                    >
                      Assinar agora
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="orion__store-section" id="orion__store-section">
          <img
            className="orion__about-image"
            src={orionStore}
            alt="Loja Orion sinais"
          />
          <div className="orion__store-content">
            <div className="orion__store-header">
              <h2 className="orion__landing-title">LOJA ORION SINAIS</h2>
              <h3>Camisetas Exclusivas Orion Sinais</h3>
              <p>
                Além dos sinais estratégicos, oferecemos camisetas exclusivas
                para você mostrar seu estilo no mundo das criptomoedas.
              </p>
            </div>
            <a
              href="https://loja.orionsinais.com.br"
              className="orion__default-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Comprar Agora
            </a>
          </div>
        </div>

        <div className="orion__faq-section" id="orion__faq-section">
          <div className="orion__faq-header">
            <h2 className="orion__landing-title">DÚVIDAS</h2>
            <h3>Confira as principais dúvidas a respeito dos sinais</h3>
            <p>
              Não conseguiu sanar todas as suas dúvidas? Estamos aqui pra
              ajudar! Envie suas dúvidas e sugestões e nos ajude a construir a
              melhor plataforma para você!
            </p>
            <a
              style={{ textDecoration: "underline" }}
              href="#orion__contact-section"
            >
              Dúvidas? Converse conosco
            </a>
          </div>
          <div className="orion__faq-content">
            <FaqSection />
          </div>
        </div>
        <div className="orion__about-section" id="orion__about-section">
          <img
            alt="Foto da escultura globo da Babson College"
            src={babson}
            className="orion__about-image"
          />
          <div className="orion__about-content">
            <img alt="Logo da Orion Sinais" src={logo}></img>
            <div className="orion__about-content-text">
              <p>
                Imersos no empreendedorismo, na Babson College em Boston/MA -
                EUA, embarcamos em uma jornada para criar a Orion Sinais unidos
                por uma paixão compartilhada por tecnologia, blockchain e
                criptomoedas.
              </p>
              <p>
                Nossos especialistas desenvolveram um algoritmo automatizado que
                enviam sinais para a plataforma e para o Telegram.{" "}
              </p>
            </div>
            <button
              className="orion__default-button"
              onClick={() => navigate("/sobre-nos")}
            >
              Saiba mais
            </button>
          </div>
        </div>
        <div className="orion__contact-section" id="orion__contact-section">
          <div className="orion__contact-section-header">
            <small>ENTRE EM CONTATO</small>
            <h1>Nossos especialistas estão prontos para atender você!</h1>
          </div>
          <div className="orion__contact-section-content">
            <div className="orion__lp-contact-info">
              <p>
                Criamos esse canal de comunicação para que você possa esclarecer
                todas as suas dúvidas:
              </p>
              <div className="orion__contact-info-content">
                <EnvelopeIcon className="orion__contact-icon" />
                <p>
                  <b>contato@orionsinais.com.br</b>
                </p>
              </div>
              <hr />
              <div className="orion__contact-info-content">
                <MapPinIcon className="orion__contact-icon" />
                <div className="orion__contact-info-address">
                  <p>
                    <b>Av. Paulista, 1636 - Conjunto 4</b>
                  </p>
                  <p>Pav. 1504 - São Paulo/SP</p>
                </div>
              </div>
            </div>
            <div className="orion__contact-form">
              <p>
                Preencha o formulário para mais informações, dúvidas ou
                sugestões
              </p>
              <iframe
                title="contact-form-iframe"
                aria-label="Entre em contato"
                frameborder="0"
                width="100%"
                height="500"
                src="https://forms.zohopublic.com/orionsinais/form/Entreemcontato1/formperma/ycV-qozeIk6iWWK0CAhMJJbdfG3JyVviuyNcQ_KzySE"
              ></iframe>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
