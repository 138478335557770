import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-light.png";
import userIcon from "../../assets/default_icon.svg";
import "./navbar.css";

import { useLocation } from "react-router-dom";

import {
  ArrowLeftEndOnRectangleIcon,
  BookOpenIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  Bars3Icon,
  WalletIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const { user, loadingUser } = useUser();
  const { logout, isAuthenticated } = useAuth();

  let location = useLocation();

  const handleLogout = () => {
    logout();
    if (!isAuthenticated()) navigate("/login");
  };

  return (
    <div className="orion__navbar">
      <div className="orion__navbar-links">
        <div className="orion__navbar-links_logo" onClick={() => navigate("/")}>
          <img src={logo} alt="logo" className="orion__nav-logo" />
        </div>
      </div>
      {location.pathname === "/assinatura" ||
      location.pathname === "/assinatura-terminada" ? (
        <></>
      ) : (
        <ul className="orion__navbar-menu">
          <li
            className={`orion__menu-item ${
              location.pathname === "/dashboard" ? "active" : ""
            }`}
            onClick={() => navigate("/dashboard")}
          >
            <WalletIcon className="orion__menu-icon" />
            <p>Dashboard</p>
          </li>
          <li
            className={`orion__menu-item ${
              location.pathname === "/sinais-abertos" ? "active" : ""
            }`}
            onClick={() => navigate("/sinais-abertos")}
          >
            <BookOpenIcon className="orion__menu-icon" />
            <p>Sinais</p>
          </li>
          <li
            className={`orion__menu-item ${
              location.pathname === "/ranking" ? "active" : ""
            }`}
            onClick={() => navigate("/ranking")}
          >
            <ChartBarIcon className="orion__menu-icon" />
            <p>Ranking</p>
          </li>
          <li
            className={`orion__menu-item ${
              location.pathname === "/configuracoes" ? "active" : ""
            }`}
            onClick={() => navigate("/configuracoes")}
          >
            <Cog6ToothIcon className="orion__menu-icon" />
            <p>Configurações</p>
          </li>
        </ul>
      )}
      <li className="orion__menu-toggle">
        <Bars3Icon
          className="orion__menu-icon-mobile"
          onClick={() => setShowMobileMenu(true)}
        />
      </li>
      {!showMobileMenu && (
        <div className="orion__profile-container">
          <a
            className="orion__default-button"
            href="https://loja.orionsinais.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nossa loja
            <BuildingStorefrontIcon className="orion__menu-icon" />
          </a>
          <div className="orion__userInfo">
            <img
              alt="Ícone que representa um usuário"
              className="orion__userInfo-img"
              src={userIcon}
            />
            <div className="orion__userInfo-text">
              <div className="orion__userInfo-username">
                {loadingUser ? "" : user.first_name + " " + user.last_name}
              </div>
              <button
                className="orion__desktop-logout-text"
                onClick={handleLogout}
              >
                Sair
              </button>
            </div>
          </div>
        </div>
      )}

      {showMobileMenu && (
        <div className="orion__menu-sidebar">
          <ul>
            <li>
              <XMarkIcon
                className="orion__menu-icon-mobile"
                onClick={() => setShowMobileMenu(false)}
              />
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
              onClick={() => navigate("/dashboard")}
            >
              <WalletIcon className="orion__menu-icon" />
              <p>Dashboard</p>
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/sinais-abertos" ? "active" : ""
              }`}
              onClick={() => navigate("/sinais-abertos")}
            >
              <BookOpenIcon className="orion__menu-icon" />
              <p>Sinais</p>
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/ranking" ? "active" : ""
              }`}
              onClick={() => navigate("/ranking")}
            >
              <ChartBarIcon className="orion__menu-icon" />
              <p>Ranking</p>
            </li>

            <li className="orion__menu-item">
              <a
                href="https://loja.orionsinais.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BuildingStorefrontIcon className="orion__menu-icon" />
                Nossa loja
              </a>
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/configuracoes" ? "active" : ""
              }`}
              onClick={() => navigate("/configuracoes")}
            >
              <Cog6ToothIcon className="orion__menu-icon" />
              <p>Configurações</p>
            </li>
            <li>
              <div className="orion__user-info-mobile">
                <img
                  alt="Ícone que representa um usuário"
                  className="orion__userInfo-img"
                  src={userIcon}
                />
                <div className="orion__userInfo-text">
                  <div className="orion__userInfo-username">
                    {loadingUser ? "" : user.first_name + " " + user.last_name}
                  </div>
                </div>
                <button
                  className="orion__logout orion__desktop-logout-text"
                  onClick={handleLogout}
                >
                  <ArrowLeftEndOnRectangleIcon className="orion__menu-icon-mobile" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
