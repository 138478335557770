import React from "react";
import "./hint.css";

function Hint({ text, children }) {
  return (
    <div className="orion__hint">
      <span>{children}</span>
      <span className="orion__hint-bubble">{text}</span>
    </div>
  );
}

export default Hint;
