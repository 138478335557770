import React, { useState, useEffect } from "react";
import "./openStrategies.css";

import { useNavigate } from "react-router-dom";
import { Footer, Navbar, PairTierIcon, SearchBar } from "../../components";
import ClipLoader from "react-spinners/ClipLoader";

import {
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

import { getPairCoinSymbol } from "../homepage/helpers/pairHelpers";

import useIcons from "../../hooks/useIcons";
import useOpenStrategies from "../../hooks/useOpenStrategies";
import useUser from "../../hooks/useUser";

const Header = ({ columns, handleHeaderClick, sort }) => {
  return (
    <thead className="orion__strategies-column-header">
      <tr>
        {columns.map((column) => (
          <th key={column.key} onClick={() => handleHeaderClick(column)}>
            <div className="header-container">
              {column.name}
              {column.key === sort.keyToSort && (
                <Icon
                  direction={
                    sort.keyToSort === column.key ? sort.direction : "asc"
                  }
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Content = ({ entries, columns }) => {
  return (
    <tbody className="orion__column-body">
      {entries.map((entry) => (
        <tr key={entry.dataCompra}>
          {columns.map((column) => (
            <Cell key={column.key} column={column} entry={entry} />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const Cell = ({ column, entry }) => {
  const { getIconByCoinSymbol } = useIcons();
  const navigate = useNavigate();

  if (column.key === "profit_percentage") {
    return (
      <td
        key={column.key}
        className={
          entry[column.key] < 0
            ? "orion__column-negative"
            : "orion__column-positive"
        }
      >
        {`${entry[column.key].toFixed(2) + "%"}`.replace(".", ",")}
      </td>
    );
  } else if (column.key === "pair") {
    return (
      <td key={column.key}>
        <div
          onClick={() => navigate(`/dashboard/${entry[column.key]}`)}
          className="orion__strategies-pair-container"
        >
          <div className="orion__strategies-tier-container">
            <PairTierIcon
              position={entry["ranking_position"]}
              tier={entry["ranking_performance_tier"]}
            />
          </div>
          <div className="coin-img-container">
            {getCoinIcon(entry[column.key]) ? (
              <img
                className="coin-img"
                alt={`Imagem correspondente do criptoativo ${entry[column.key]}`}
                src={getIconByCoinSymbol(getPairCoinSymbol(entry[column.key]))}
              />
            ) : (
              <small>{entry[column.key].substring(0, 1)}</small>
            )}
          </div>
          <span>{formatPair(entry[column.key])}</span>
        </div>
      </td>
    );
  } else if (column.key === "last_candle_date") {
    return <td key={column.key}>{formatDate(entry[column.key])}</td>;
  } else if (column.key === "open_time") {
    return (
      <td key={column.key}>
        {entry[column.key] > 1
          ? entry[column.key] + " dias"
          : entry[column.key] + " dia"}
      </td>
    );
  } else {
    return (
      <td key={column.key}>
        {`${Number(entry[column.key]).toFixed(8)}`.replace(".", ",")}
      </td>
    );
  }
};

const Icon = ({ direction }) => {
  return direction === "asc" ? (
    <ChevronUpIcon className="orion__table-icon" />
  ) : (
    <ChevronDownIcon className="orion__table-icon" />
  );
};

const formatPair = (pair) => {
  return (
    pair.substring(0, pair.length - 4) +
    "/" +
    pair.substring(pair.length - 4, pair.length)
  );
};

const formatDate = (date) => {
  date = date.substring(0, 10).replaceAll("-", "/");
  const dateArray = date.split("/");
  const formattedDate = [];

  formattedDate.push(dateArray[2], "/", dateArray[1], "/", dateArray[0]);

  return formattedDate.join().replaceAll(",", "");
};

const getCoinIcon = (pairSymbol) => {
  let symbol = pairSymbol.substring(0, pairSymbol.length - 4).toLowerCase();

  try {
    const iconPath = require(`../../assets/crypto-icons/${symbol}.png`);
    return iconPath;
  } catch (err) {
    return null;
  }
};

function OpenStrategies() {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    keyToSort: "profit_percentage",
    direction: "desc",
  });

  const { openStrategies, loadingOpenStrategies } = useOpenStrategies();
  const { user } = useUser();
  const columns = [
    {
      key: "pair",
      name: "Par",
    },
    {
      key: "last_candle_date",
      name: "Data da Compra",
    },
    {
      key: "last_close",
      name: "Valor da Compra",
    },
    {
      key: "current_price",
      name: "Valor Parcial",
    },
    {
      key: "open_time",
      name: "Tempo em aberto",
    },
    {
      key: "profit_percentage",
      name: "Percentual de Lucro",
    },
  ];

  const navigate = useNavigate();

  const formatDataWithOpenTime = (data) => {
    const formattedData = [...data];

    for (let item in data) {
      let openTime = getSignalOpenTime(data[item].last_candle_date);
      data[item].open_time = openTime;
    }

    return formattedData;
  };

  const handleHeaderClick = (header) => {
    setSort({
      keyToSort: header.key,
      direction:
        header.key === sort.keyToSort
          ? sort.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const getSignalOpenTime = (buyDate) => {
    let buyDateFormatted = new Date(buyDate.split(" ")[0]);
    let currentDate = new Date();
    let differenceInDays = Math.floor(
      (currentDate - buyDateFormatted) / (1000 * 60 * 60 * 24),
    );

    return differenceInDays;
  };

  const getSortedArray = (array) => {
    if (
      sort.keyToSort === "last_close" ||
      sort.keyToSort === "open_time" ||
      sort.keyToSort === "current_price" ||
      sort.keyToSort === "profit_percentage"
    ) {
      if (sort.direction === "asc") {
        return array.sort((a, b) =>
          Number(a[sort.keyToSort]) > Number(b[sort.keyToSort]) ? 1 : -1,
        );
      } else {
        return array.sort((a, b) =>
          Number(a[sort.keyToSort]) > Number(b[sort.keyToSort]) ? -1 : 1,
        );
      }
    } else {
      if (sort.direction === "asc") {
        return array.sort((a, b) =>
          a[sort.keyToSort] > b[sort.keyToSort] ? 1 : -1,
        );
      } else {
        return array.sort((a, b) =>
          a[sort.keyToSort] > b[sort.keyToSort] ? -1 : 1,
        );
      }
    }
  };

  useEffect(() => {
    if (user && user.subscription === null) {
      navigate("/assinatura");
    } else if (user.subscription && !user.subscription.active) {
      navigate("/assinatura-terminada");
    }
  }, [user.subscription]);

  useEffect(() => {
    if (openStrategies) setTableData(formatDataWithOpenTime(openStrategies));
  }, [openStrategies]);

  return (
    <div>
      <Navbar />
      <div className="orion__strategies-header">
        <div className="orion__strategies-title">
          <button
            className="orion__strategies-return-button"
            onClick={() => navigate("/dashboard")}
          >
            <ChevronLeftIcon className="icon" />
          </button>
          <h1>Sinais em aberto</h1>
        </div>
        <SearchBar
          placeholder="Pesquisar por uma criptomoeda..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="orion__open-strategies-content">
        {loadingOpenStrategies ? (
          <ClipLoader
            color="#D42A5B"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="orion__loading"
          />
        ) : (
          <div className="orion__strategies-table-container">
            <table className="orion__strategies-table">
              <Header
                columns={columns}
                handleHeaderClick={handleHeaderClick}
                sort={sort}
              />
              <Content
                entries={getSortedArray(tableData).filter((item) => {
                  return search === ""
                    ? item
                    : item.pair.toLowerCase().includes(search.toLowerCase());
                })}
                columns={columns}
              />
            </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default OpenStrategies;
