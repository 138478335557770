import React, { useState, useEffect } from "react";
import "./ranking.css";
import { useNavigate } from "react-router-dom";
import {
  Footer,
  Navbar,
  PairTierIcon,
  SearchBar,
  Tooltip,
} from "../../components";
import {
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { getPairCoinSymbol } from "../homepage/helpers/pairHelpers";
import useIcons from "../../hooks/useIcons";
import ClipLoader from "react-spinners/ClipLoader";
import useRanking from "../../hooks/useRanking";

const Icon = ({ direction }) => {
  return direction === "asc" ? (
    <ChevronUpIcon className="orion__table-icon" />
  ) : (
    <ChevronDownIcon className="orion__table-icon" />
  );
};

const Header = ({ columns, handleHeaderClick, sort }) => {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.key} onClick={() => handleHeaderClick(column)}>
            <div className="header-container">
              {column.name}
              {column.key === sort.keyToSort && (
                <Icon
                  direction={
                    sort.keyToSort === column.key ? sort.direction : "asc"
                  }
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const Content = ({ entries, columns }) => {
  return (
    <tbody className="orion__column-body">
      {entries.map((entry, index) => (
        <tr key={index}>
          {columns.map((column) => (
            <Cell key={column.key} column={column} entry={entry} />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const Cell = ({ column, entry }) => {
  const { getIconByCoinSymbol } = useIcons();
  const navigate = useNavigate();

  if (column.key === "profitSum") {
    return (
      <td
        key={column.key}
        className={
          entry[column.key] < 0
            ? "orion__column-negative"
            : "orion__column-positive"
        }
      >
        {`${entry[column.key].toFixed(2) + "%"}`.replace(".", ",")}
      </td>
    );
  } else if (column.key === "pair") {
    return (
      <td key={column.key}>
        <div
          onClick={() => navigate(`/dashboard/${entry[column.key]}`)}
          className="orion__ranking-pair-container"
        >
          <div className="orion__ranking-tier-container">
            <PairTierIcon
              position={entry["position"]}
              tier={entry["performanceTier"]}
            />
          </div>
          <div className="coin-img-container">
            {getCoinIcon(entry[column.key]) ? (
              <img
                alt={`Imagem correspondente do criptoativo ${entry[column.key]}`}
                className="coin-img"
                src={getIconByCoinSymbol(getPairCoinSymbol(entry[column.key]))}
              />
            ) : (
              <small>{entry[column.key].substring(0, 1)}</small>
            )}
          </div>
          <span>{formatPair(entry[column.key])}</span>
        </div>
      </td>
    );
  } else {
    return <td key={column.key}>{entry[column.key]}</td>;
  }
};

const formatPair = (pair) => {
  return (
    pair.substring(0, pair.length - 4) +
    "/" +
    pair.substring(pair.length - 4, pair.length)
  );
};

const getCoinIcon = (pairSymbol) => {
  let symbol = pairSymbol.substring(0, pairSymbol.length - 4).toLowerCase();

  try {
    const iconPath = require(`../../assets/crypto-icons/${symbol}.png`);
    return iconPath;
  } catch (err) {
    return null;
  }
};

function Ranking() {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    keyToSort: "profitSum",
    direction: "desc",
  });
  const { ranking: rankingData, loadingRanking: loadingRankingData } =
    useRanking();

  const navigate = useNavigate();

  const columns = [
    { key: "pair", name: "Par" },
    { key: "hits", name: "Acertos" },
    { key: "misses", name: "Erros" },
    { key: "profitSum", name: "Resultado Acumulado" },
  ];

  const handleHeaderClick = (header) => {
    setSort({
      keyToSort: header.key,
      direction:
        header.key === sort.keyToSort
          ? sort.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const getSortedArray = (array) => {
    if (!array) return [];

    if (
      sort.keyToSort === "profitSum" ||
      sort.keyToSort === "hits" ||
      sort.keyToSort === "misses"
    ) {
      if (sort.direction === "asc") {
        return array.sort(
          (a, b) => Number(a[sort.keyToSort]) - Number(b[sort.keyToSort]),
        );
      } else {
        return array.sort(
          (a, b) => Number(b[sort.keyToSort]) - Number(a[sort.keyToSort]),
        );
      }
    } else {
      if (sort.direction === "asc") {
        return array.sort((a, b) =>
          a[sort.keyToSort] > b[sort.keyToSort] ? 1 : -1,
        );
      } else {
        return array.sort((a, b) =>
          a[sort.keyToSort] > b[sort.keyToSort] ? -1 : 1,
        );
      }
    }
  };

  useEffect(() => {
    if (rankingData) {
      setTableData(rankingData);
    }
  }, [rankingData]);

  return (
    <div>
      <Navbar />
      <div className="orion__ranking-header">
        <div className="orion__ranking-title">
          <button
            className="orion__ranking-return-button"
            onClick={() => navigate("/dashboard")}
          >
            <ChevronLeftIcon className="icon" />
          </button>
          <h1>Ranking</h1>
          <Tooltip>
            <p>
              <strong>Acertos:</strong> Quantidade de operações no qual o sinal
              de venda resultou em lucro após seguir o sinal de compra anterior.
            </p>
            <br />
            <p>
              <strong>Erros:</strong> Quantidade de operações que não atingiram
              o resultado esperado entre o sinal de compra e venda.
            </p>
            <br />
            <p>
              <strong>Resultado acumulado:</strong> Soma total dos percentuais
              de lucro/prejuízo de todas as operações realizadas (compra +
              venda).
            </p>
          </Tooltip>
        </div>
        <SearchBar
          placeholder="Pesquisar por um ranking..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="orion__ranking-content">
        {loadingRankingData ? (
          <ClipLoader
            color="#D42A5B"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="orion__loading"
          />
        ) : (
          <div className="orion__ranking-table-container">
            <table className="orion__ranking-table">
              <Header
                columns={columns}
                handleHeaderClick={handleHeaderClick}
                sort={sort}
              />
              <Content
                entries={getSortedArray(tableData).filter((item) => {
                  return search === ""
                    ? item
                    : item.pair.toLowerCase().includes(search.toLowerCase());
                })}
                columns={columns}
              />
            </table>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Ranking;
