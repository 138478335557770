import React, { useState, useEffect } from "react";
import "./index.css";
import logoSymbol from "../../../../assets/logo-light-symbol.png";
import logo from "../../../../assets/logo-light.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navLinks = [
  { href: "/#orion__landing-presentation", text: "Como funciona?" },
  { href: "/#orion__features-section", text: "Benefícios" },
  { href: "/#orion__plan-section", text: "Planos" },
  { href: "/#orion__store-section", text: "Loja" },
  { href: "/#orion__faq-section", text: "Dúvidas" },
  { href: "/sobre-nos", text: "Quem somos?" },
];

function Navbar() {
  const breakpoint = 900;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const NavLinks = ({ mobile }) => (
    <ul className={mobile ? "orion__landing-navbar-sidebar" : ""}>
      {mobile && (
        <li>
          <XMarkIcon className="orion__lp-menu-icon" onClick={toggleSidebar} />
        </li>
      )}
      {navLinks.map(({ href, text }, index) => (
        <li key={index}>
          <a href={href} onClick={() => mobile && toggleSidebar()}>
            {text}
          </a>
        </li>
      ))}
      {mobile && (
        <li>
          <NavAuth />
        </li>
      )}
    </ul>
  );

  const NavAuth = () => (
    <div className="orion__navbar-buttons">
      <a className="link" href="/login">
        Entrar
      </a>
      <a className="orion__default-button" href="/cadastro">
        Cadastre-se
      </a>
    </div>
  );

  return (
    <header className="orion__landing-navbar">
      <a href="/">
        <img
          alt="Logo da Orion sinais"
          src={isMobile ? logoSymbol : logo}
          className="orion__landing-nav-logo"
        />
      </a>

      <nav>
        {isMobile ? (
          <>
            {isSidebarOpen && <NavLinks mobile />}
            <Bars3Icon
              className="orion__lp-menu-icon"
              onClick={toggleSidebar}
            />
          </>
        ) : (
          <div className="orion__lp-navbar-items">
            <NavLinks />
            <NavAuth />
          </div>
        )}
      </nav>
    </header>
  );
}

export default Navbar;
