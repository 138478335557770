import React, { useEffect, useState } from "react";
import "./login.css";

import { useNavigate } from "react-router-dom";
import { LoginSignUpLayout } from "../../layouts";
import { Input, Button } from "../../components";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ClipLoader } from "react-spinners";

import useAuth from "../../hooks/useAuth";

function Login() {
  const [showAlert, setShowAlert] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);

  const navigate = useNavigate();
  const { signin, error, isAuthenticated } = useAuth();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (user.email && user.password) {
      setShowAlert(false);
      await signin(user).then(async () => {
        if (isAuthenticated()) navigate("/dashboard");
      });
    } else if (!user.email || !user.password) {
      setLoading(false);
      setShowAlert(true);
      setWarningText("Por favor, preencha todos os campos");
      return;
    }
  };

  const navigateToSignup = () => {
    navigate("/cadastro");
  };

  const navigateToRequestPasswordReset = () => {
    navigate("/reset_password");
  };

  const navigateToResendConfirmationEmail = () => {
    navigate("/resend_confirmation_email");
  };

  useEffect(() => {
    if (error && error.error === "User or password is invalid") {
      setLoading(false);
      setShowAlert(true);
      setWarningText("E-mail ou senha incorretos");
    }
  }, [error]);

  useEffect(() => {
    console.log(loggedUser);
    if (loggedUser?.id) {
      console.log(loggedUser);
      localStorage.setItem("subscription", loggedUser.subscription);
      navigate("/dashboard");
    }
  }, [loggedUser]);
  return (
    <div>
      <LoginSignUpLayout>
        <form className="orion__login-form" onSubmit={handleLogin}>
          <h1>Entrar</h1>
          <div className="orion__input-wrapper">
            <Input
              type="email"
              placeholder="nome@email.com"
              label="e-mail"
              onChange={(e) =>
                setUser({
                  ...user,
                  email: e.target.value,
                })
              }
            />
            <Input
              type="password"
              placeholder="senha"
              label="senha"
              onChange={(e) =>
                setUser({
                  ...user,
                  password: e.target.value,
                })
              }
            />
            <div className="orion__login-helpers">
              <small onClick={navigateToRequestPasswordReset}>
                Esqueceu sua senha?
              </small>
              <small
                onClick={navigateToResendConfirmationEmail}
                style={{ textAlign: "right" }}
              >
                Re-enviar e-mail de confirmação
              </small>
            </div>
          </div>

          {showAlert ? (
            <div className="orion__warning">
              <ExclamationCircleIcon className="orion__warning-icon" />
              <small>{warningText}</small>
            </div>
          ) : (
            ""
          )}

          {loading ? (
            <Button styleType="filled">
              <ClipLoader
                color="#ffffff"
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="orion__loading"
              />
            </Button>
          ) : (
            <Button text="Entrar" styleType="filled" onClick={handleLogin} />
          )}

          <Button
            text="Não tem uma conta? Cadastre-se"
            type="submit"
            styleType="outlined"
            onClick={navigateToSignup}
          />
        </form>
      </LoginSignUpLayout>
    </div>
  );
}

export default Login;
